<template>
  <div class="payment">
    <!-- <div class="screening">
      <van-dropdown-menu active-color="#1677ff">
        <van-dropdown-item v-model="screening" :options="screeningList" @change='onScreeningChange'/>
      </van-dropdown-menu>
    </div> -->
    <div class="main-part">
      <div  v-if='bill_card_list.length == 0 && !flag' class="part">
        <van-empty description="暂无账单记录" />
      </div>
      <van-pull-refresh v-else v-model="isLoading" @refresh="onRefresh" style="height: 100%">
        <van-list
          v-model="loading"
          :offset="50"
          :immediate-check="false"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoadData"
        >
          <div
            class="main-part-item"
            v-for="item in bill_card_list"
            :key="item.bill_id"
          >
            <div class="item-head">
              <span class="title">账单分期</span>
              <span class="all-bill" @click="onAllOrder(item.bill_id)"
                >全部账单<van-icon class="icon-right" name="arrow"
              /></span>
            </div>
            <div class="item-body">
              <div class="stages-data">
                <div class="data-content">
                  <div class="data-content_name">总金额</div>
                  <div class="data-content_value">{{ item.total_repayment_amount }}</div>
                </div>
                <div class="data-content">
                  <div class="data-content_name">总期数</div>
                  <div class="data-content_value">{{ item.total_stage_count }}期</div>
                </div>
                <div class="data-content">
                  <div class="data-content_name">办理时间</div>
                  <div class="data-content_value">{{ item.apply_stage_date }}</div>
                </div>
              </div>
              <div class="recently-repayment">
                <div class="stages">
                  <div class="periods-price">
                    <div class="periods">
                      第{{ item.bill_stage_info.current_stage }}期待付金额（元）
                    </div>
                    <div class="price">
                      {{ item.bill_stage_info.repayment_amount }}
                    </div>
                  </div>
                  <div class="periods-date">
                    <div class="periods">到期日{{ item.bill_stage_info.repayment_date }}</div>
                    <div v-if="!item.is_overdue" class="state">
                      <span v-if="item.bill_stage_info.due_date!=0">{{ item.bill_stage_info.due_date }}日后到期</span>
                      <span v-else>今日到期，请及时还款</span>
                    </div>
                    <div v-else style="color:ffb800">已逾期</div>
                  </div>
                </div>
                <div class="stages-operate">
                  <div class="payment-button" @click="getRepay(item.bill_stage_info.bill_stage_id)">按期付款</div>
                  <!-- <div class="multiperiod">我要付多期<van-icon name="question-o" style="margin:0px 5px"/></div> -->
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { billCardList } from "@/api/bill.js";
export default {
  name: "",
  components: {},
  data() {
    return {
      screening:0,
      screeningList:[
        { text: '全部帐单', value: 0 },
        { text: '已结清帐单', value: 1 },
        { text: '未结清帐单', value: 2 },
        { text: '逾期帐单', value: 3 },
      ],
      loading: false,
      isLoading: false,
      finished: false,
      searchForm: {
        billStatus:2,
        page: 1,
        pageSize: 10,
      },
      bill_card_list: [],
      flag:false
    };
  },
  computed: {},
  mounted() {
    this.initData();
  },
  methods: {
    async initData() {
      this.flag = true
      const { data, code } = await billCardList(this.searchForm);
      if (code == 200) {
        this.flag = false
        let list = data.list;
        if (this.searchForm.page == 1) {
          this.bill_card_list = list;
        } else {
          this.bill_card_list = this.bill_card_list.concat(list);
        }
      }
      this.loading = false;
      if (data.currentPage == data.totalPage || data.totalPage == 0) {
        this.finished = true;
      }
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    },
    onLoadData() {
      if (!this.finished) {
        this.loading = true;
        this.searchForm.page++;
        this.initData();
      }
    },
    onRefresh() {
      this.finished = false;
      this.loading = true;
      this.searchForm.page = 1;
      this.initData();
    },
    onScreeningChange(value){
      this.searchForm.billStatus = value
      this.initData()
    },
    onAllOrder(bill_id) {
      localStorage.setItem('returnPath',"/payment")
      this.$router.push({
        path:"/paymentSchedule",
        query:{
          bill_id:bill_id
        }
      });
    },
    getRepay(bill_stage_id){
      localStorage.setItem('returnOrderPath',"/payment")
      this.$router.push({
        name: 'schedulePayment',
        query:{
          bill_stage_id:bill_stage_id
        }
      });
    }
  },
};
</script>
<style scoped lang="scss">
.payment {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .screening{
    border-top: 2px solid #e6e6e6;
    box-shadow: 0px 0px 10px 0px #f5f5f5;
  }
  .main-part {
    flex: 1;
    background-color: #000000;
    height: 100%;
    overflow: auto;
    padding: 30px;
    .part{
      height: 100%;
    }
    .main-part-item {
      margin-bottom: 20px;
      background: #ffffff;
      border-radius: 32px;
      .item-head {
        display: flex;
        align-items: center;
        background-image: url("../../assets/img/payment_header_bg.png");
        background-size: 100%;
        .title {
          font-size: 36px;
          margin: 28px 0px 24px 40px ;
          font-weight: 600;
          color: #552C00;
          flex: 1;
        }
        .all-bill {
          font-size: 24px;
          color: #E69A48;
          .icon-right {
            margin: 0px 20px;
            color: #E69A48;
          }
        }
      }
      .item-body {
        margin-top: -10px;
        background: #FFFFFF;
        border-radius: 32px;
        padding-bottom: 40px;
        .stages-data {
          display: flex;
          padding: 20px 0px;
          .data-content {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #333333;
            font-size: 30px;
            line-height: 2;
            .data-content_name {
              font-size: 28px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #0B0601;
              line-height: 46px
            }
            .data-content_value {
              font-size: 32px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #0B0601;
              line-height: 46px;
            }
          }
        }
        .recently-repayment {
          background: #FDF9F0;
          border-radius: 32px;
          border: 1px solid #FFCDB0;
          margin: 0 40px;
          padding: 32px;
          font-size: 24px;
          .repayment-status{
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 40px;
            font-weight: 600;
          }
          .stages {
            display: flex;
            .periods-price {
              flex: 1;
              .price {
                font-size: 64px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #E69B4A;
                margin-top: 30px;
                line-height: 46px;
              }
              .periods {
                font-size: 28px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #0B0601;
                line-height: 46px;
              }
            }
            .periods-date {
              text-align: right;
              line-height: 2;
              .periods {
                font-size: 28px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #0B0601;
                line-height: 46px;
              }
              .state {
                font-size: 32px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #0B0601;
                line-height: 46px;
              }
            }
          }
          .stages-operate {
            margin-top: 48px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .payment-button {
              width: 100%;
              padding: 17px 100px;
              border-radius: 32px;
              border: 1px solid #FFCDB0;
              background: linear-gradient(90deg, #FFCE9B 0%, #E69A48 100%);
              font-size: 32px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #FFFFFF;
              text-align: center;
              line-height: 45px;
            }
            .multiperiod {
              margin-top: 20px;
              color: #b3d3ff;
            }
          }
        }
      }
    }
  }
}
</style>
